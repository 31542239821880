<template>
    <div class="row" v-bind:class="{'out-information': this.flag == 1, 'in-information': this.flag == 0}">
        <h2 class="col-md-4">
          {{this.flag == 1 ? 'OUT' : 'IN'}}
        </h2>
        <div class="col-md-4">
          <b-card v-bind:class="calculatePercentage(data)">
              <b-card-text class="card-text">
                  <h2>{{this.flag == 1 ? data.totalOut : data.totalIn}}</h2>
              </b-card-text>
          </b-card>
        </div>
      </div>
</template>

<script>
  export default {
    name: 'CardStat',
    props: {
        data: Object,
        flag: Number
    },
    data(){
        return {
            percentage: 0
        }
    },
    methods: {
      calculatePercentage({totalIn, capacity}) {
          this.percentage = Math.round(totalIn / capacity * 100);
          if(this.percentage <= 50 && this.flag == 0) return 'low-crowd text-light'
          if(this.percentage <= 80 && this.flag == 0) return 'medium-crowd text-light'
          if(this.percentage <= 90 && this.flag == 0) return 'high-crowd text-light'
          if(this.percentage > 90 && this.flag == 0) return  'full-crowd text-light'
          if(this.flag == 1) return ''
      }
    }
  }
</script>

<style scoped>
  .low-crowd {
    background-color: green;
  }

  .medium-crowd {
    background-color: yellow;
  }

  .high-crowd {
    background-color: orange;
  }

  .full-crowd {
    background-color: red;
  }
</style>