const axios = require('axios');

const APIClient = axios.create({
    baseURL: 'https://gateway.reg-gemindonesia.net/',
    timeout: 5000,
})

const fetchInformationStat = async ({code}) => {
    return await APIClient.get(`api/v1/registrations/stats?code=${code}`)
}

const getBannerImages = async({code}) => {
    return await APIClient.post(`api/v1/registrations/stats?code=${code}`);
}

const getExhibitions = async() => {
    return await APIClient.get(`api/v1/registrations/exhibition`);
}

export {
    fetchInformationStat,
    getBannerImages,
    getExhibitions
}