<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      controls
      background="#ababab"
      img-width="1024"
      img-height="480"
      indicators
      :interval="bannerInterval()"
      style="text-shadow: 1px c1px 2px #333;"
      @sliding-start="onSlideStart"
    >
      <b-carousel-slide
        v-for="item in getBannerList()"
        :key="item.Code"
        :img-src="`https://reg-gemindonesia.net/images/${item.Code}.png`"
      >
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  export default {
    name: 'MainDashboard',
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    props:{
      exhibitions: [],
      bannerConfig: {
        isAutoSlide: false,
        timer: 0
      }
    },
    methods: {
      onSlideStart(slide) {
        const exhibitions = this.exhibitions[slide];
        this.$emit('onSlideSelect', exhibitions)
      },
      getBannerList(){
        return this.exhibitions
      },
      bannerInterval(){
        return this.bannerConfig.isAutoSlide ? this.bannerConfig.timer : 0
      },
    },
    mounted(){
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
