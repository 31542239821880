<template>
  <div class="container-fluid pt-3" id="app">
    <MainDashboard :exhibitions="this.exhibitions" v-on:onSlideSelect="onSlideSelect"
      :bannerConfig="getBannerConfig()" />

    <h3 class="main-header">
      VISITOR
    </h3>

    <CardStat :flag="0" v-bind:data="this.dataCard" />
    <h3>
      Max capacity is: <b-badge variant="danger">{{ this.dataCard.capacity }}</b-badge>
    </h3>
    <CardStat :flag="1" v-bind:data="this.dataCard" />
  </div>
</template>

<script>
import MainDashboard from './components/MainDashboard.vue'
import CardStat from './components/CardStat.vue'
import * as api from './services/api'
import config from '../public/config.json'

export default {
  name: 'App',
  components: {
    MainDashboard,
    CardStat
  },
  data() {
    return {
      codeContext: null,
      dataCard: {
        totalIn: 0,
        totalOut: 0,
        totalAll: 0,
        capacity: 0
      },
      exhibitions: [],
      filteredExhibitions: []
    }
  },
  async mounted() {
     api.getExhibitions().then(res => {

      var codes = config.BannerCodeForSlider.split(",");
      codes.forEach(c => {
        var ex = res.data.find(e => e.Code === c);
        console.log(ex)
        if (ex) this.exhibitions.push(ex);
      })

      if(this.exhibitions){
        this.codeContext = this.exhibitions[0].Code
      }

      setInterval(async () => {
        await this.fetchApi(this.codeContext)
      }, config.IntervalCallback)

      this.fetchApi(this.codeContext);
    });
  },
  methods: {
    async onSlideSelect(cb) {
      this.codeContext = cb.Code;
      await this.fetchApi(this.codeContext)
    },
    async fetchApi(code) {
      const { data } = await api.fetchInformationStat({ code: code })

      const { TotalIn, TotalOut, TotalAll, Capacity } = data
      this.dataCard = {
        totalIn: TotalIn,
        totalOut: TotalOut,
        totalAll: TotalAll,
        capacity: Capacity
      }
    },
    getBannerConfig() {
      const _isAutoSlide = config.BannerConfig.AutoSlide == 'true' ? true : false;
      const _timer = parseInt(config.BannerConfig.Timer);
      return {
        isAutoSlide: _isAutoSlide,
        timer: _timer
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.main-header {
  padding: .5rem;
  margin-top: 3rem;
}

.out-information {
  margin: 3em;
}

/* .container-fluid {
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.2);
} */

.in-information {
  margin: 3em 3em .5em 3em;
}

.row>h2 {
  text-align: right;
}

@media (max-width: 400px) {
  .row>h2 {
    text-align: center;
  }
}
</style>
